define("melis-recovery/templates/components/tx-builder-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2evI3isj",
    "block": "{\"symbols\":[\"tx\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui form\"],[9],[7,\"h4\"],[11,\"class\",\"ui dividing header\"],[9],[0,\"Choose destination address (\"],[1,[27,\"coin-decoder\",[[23,[\"recoveryInfo\",\"accountInfo\",\"coin\"]]],null],false],[0,\") \"],[10],[4,\"if\",[[23,[\"oneBig\"]]],null,{\"statements\":[[0,\"Creating one transaction redeeming all unspents\"]],\"parameters\":[]},{\"statements\":[[0,\"One transaction per unspent\"]],\"parameters\":[]}],[7,\"table\"],[11,\"class\",\"ui celled table\"],[9],[7,\"thead\"],[9],[7,\"tr\"],[9],[7,\"th\"],[9],[0,\"Input Amount\"],[10],[7,\"th\"],[9],[0,\"Fees\"],[10],[7,\"th\"],[9],[0,\"Output Amount\"],[10],[7,\"th\"],[9],[0,\"Send to address\"],[10],[10],[10],[7,\"tbody\"],[9],[4,\"each\",[[23,[\"txBuilder\"]]],null,{\"statements\":[[7,\"tr\"],[9],[7,\"td\"],[9],[1,[22,1,[\"amount\"]],false],[10],[7,\"td\"],[9],[1,[22,1,[\"fees\"]],false],[10],[7,\"td\"],[9],[1,[27,\"sub\",[[22,1,[\"amount\"]],[22,1,[\"fees\"]]],null],false],[10],[7,\"td\"],[9],[1,[27,\"input\",null,[[\"placeholder\",\"value\",\"required\"],[\"bitcoin address\",[22,1,[\"address\"]],true]]],false],[10],[10]],\"parameters\":[1]},null],[10],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "melis-recovery/templates/components/tx-builder-form.hbs"
    }
  });

  _exports.default = _default;
});