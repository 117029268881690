define("melis-recovery/templates/components/unspent-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CaZiQyyC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"is-equal\",[[23,[\"status\"]],[26]],null]],null,{\"statements\":[[0,\"UNKNOWN\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],\"redeemable\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"ui green horizontal label\"],[9],[0,\"REDEEMABLE\"],[10]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],\"timelocked\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"ui purple horizontal label\"],[9],[0,\"TIMELOCKED\"],[10]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],\"spent\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"ui grey horizontal label\"],[9],[0,\"SPENT\"],[10]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],\"error\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"ui red horizontal label\"],[9],[0,\"API error\"],[10]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],\"loading\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"ui active centered inline loader\"],[9],[10]],\"parameters\":[]},{\"statements\":[[1,[21,\"status\"],false]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "melis-recovery/templates/components/unspent-status.hbs"
    }
  });

  _exports.default = _default;
});