define("melis-recovery/helpers/coin-decoder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.coinDecoder = coinDecoder;
  _exports.default = void 0;

  function coinDecoder(params
  /*, hash*/
  ) {
    const coin = params[0];

    switch (coin) {
      case 'BTC':
        return "Bitcoin";

      case 'TBTC':
        return "Bitcoin testnet";

      case 'RBTC':
        return "Bitcoin regtest (UNSUPPORTED)";

      case 'BCH':
        return "Bitcoin Cash";

      case 'TBCH':
        return "Bitcoin Cash testnet";

      case 'RBCH':
        return "Bitcoin Cash regtest (UNSUPPORTED)";

      case 'LTC':
        return "Litecoin";

      case 'TLTC':
        return "Litecoin testnet";

      case 'RLTC':
        return "Litecoin regtest (UNSUPPORTED)";

      case 'GRS':
        return "Groestlcoin";

      case 'TGRS':
        return "Groestlcoin testnet";

      case 'RGRS':
        return "Groestlcoin regtest (UNSUPPORTED)";

      default:
        return "Unknown coin";
    }
  }

  var _default = Ember.Helper.helper(coinDecoder);

  _exports.default = _default;
});