define("melis-recovery/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rlkaAmU5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui container\"],[9],[7,\"div\"],[11,\"class\",\"ui raised segment\"],[9],[7,\"div\"],[11,\"class\",\"ui top attached teal label\"],[9],[0,\"Melis Recovery Tool\"],[10],[1,[21,\"recovery-wizard\"],false],[10],[1,[21,\"outlet\"],true],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "melis-recovery/templates/application.hbs"
    }
  });

  _exports.default = _default;
});